import React, { useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { useBreakpoint } from 'modules/breakpoint'

import ArrowIcon from '../../assets/icons/ArrowIcon'
import CheckIcon from '../../assets/icons/CheckIcon'
import { Button, Wrapper, SlideTop, Loader } from '../../components'

import { SNewsletter } from './Newsletter.styled'

import { AppRoute } from 'const'
import client from 'api/client'

const mailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
const IDLE_STATUS = 'idle'
const PENDING_STATUS = 'pending'
const SUCCESS_STATUS = 'success'
const ERROR_STATUS = 'error'

export const SUBSCRIBED_RESULT = 'subscribed'
export const UNSUBSCRIBED_RESULT = 'unsubscribed'
export const FAILED_RESULT = 'failed'

function Newsletter() {
  const [status, setStatus] = useState(IDLE_STATUS)
  const [errorMessage, setErrorMessage] = useState(null)
  const newsletterInputRef = useRef()
  const breakpoint = useBreakpoint()
  const { formatMessage, locale } = useIntl()

  const handleInput = () => {
    if (status === ERROR_STATUS) {
      setStatus(IDLE_STATUS)
    }
  }

  const handleSubmit = async (e) => {
    try {
      e.preventDefault()
      setStatus(PENDING_STATUS)
      const { value } = newsletterInputRef.current

      if (!mailRegex.test(value)) {
        setStatus(ERROR_STATUS)
        return
      }

      const response = await client({
        endpoint: 'api/newsletter',
        body: JSON.stringify({
          email: value,
        }),
        method: 'POST',
        headers: {
          'Accept-Language': locale,
          'Content-Type': 'application/json',
        },
      })

      const data = await response.json()
      if (!response.ok) {
        setStatus(ERROR_STATUS)

        if (data?.errors?.email && Array.isArray(data.errors.email)) {
          setErrorMessage(data.errors.email[0])
        }

        return
      }

      setStatus(SUCCESS_STATUS)
    } catch (error) {
      console.log('Newsletter join error', error)
      if (typeof error === 'string') setErrorMessage(error)
      setStatus(ERROR_STATUS)
    }
  }

  const error = status === ERROR_STATUS
  const loading = status === PENDING_STATUS
  const success = status === SUCCESS_STATUS

  return (
    <SlideTop>
      <Wrapper>
        <SNewsletter {...{ error, loading, success }}>
          <h2>
            {formatMessage(
              {
                id: 'newsletterHeading',
              },
              {
                span: (chunk) => <span>{chunk}</span>,
              }
            )}
          </h2>
          <form
            id="newsletter-form"
            name="newsletter-form"
            onSubmit={handleSubmit}
          >
            {success ? (
              <div className="success">
                <p>{formatMessage({ id: 'newsletterSuccess' })}</p>
                <span>
                  <CheckIcon />
                </span>
              </div>
            ) : (
              <div>
                <input
                  ref={newsletterInputRef}
                  type="text"
                  onChange={handleInput}
                  placeholder={formatMessage({
                    id: 'newsletterPlaceholder',
                  })}
                />
                {breakpoint.lg ? (
                  <Button disabled={error}>
                    {formatMessage({ id: 'newsletterSubscribe' })}
                  </Button>
                ) : (
                  <button disabled={error} type="submit">
                    {status === PENDING_STATUS ? <Loader /> : <ArrowIcon />}
                  </button>
                )}
              </div>
            )}
            {!success ? (
              <p>
                {error && errorMessage
                  ? errorMessage
                  : formatMessage(
                      {
                        id: error ? 'newsletterError' : 'newsletterPolicy',
                      },
                      {
                        a: ([chunk]) => {
                          return (
                            <a
                              href={formatMessage({
                                id: AppRoute.PrivacyPolicy,
                              })}
                              target="_blank"
                            >
                              {chunk}
                            </a>
                          )
                        },
                      }
                    )}
              </p>
            ) : null}
          </form>
        </SNewsletter>
      </Wrapper>
    </SlideTop>
  )
}

export default Newsletter
