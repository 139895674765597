import React, { useMemo, useState } from 'react'
import { useTheme } from 'styled-components'
import { useForm, Controller } from 'react-hook-form'
import { useBreakpoint } from 'modules/breakpoint'
import { useIntl } from 'react-intl'
import { useFormspark } from '@formspark/use-formspark'
import { useSelector } from 'react-redux'
import useLocalizeRoute from 'utils/useLocalizeRoute'

import AccommodationIcon from '../../assets/speakers-amenities/AccommodationIcon'
import TransportIcon from '../../assets/speakers-amenities/TransportIcon'
import PadlockIcon from '../../assets/speakers-amenities/PadlockIcon'
import SupperIcon from '../../assets/speakers-amenities/SupperIcon'

import { SectionHeader, Select, Wrapper } from '../../components'
import {
  SAmenities,
  SAmenitiesList,
  SComment,
  SComments,
  SBecomeSpeaker,
  SContainer,
  SFormContainer,
  SSend,
  SFormPolicy,
  SFormGroup,
} from './BecomeSpeaker.styled'

import technologies from './technologies'
import BecomeSpeakerSuccess from './BecomeSpeakerSuccess'
import { AppRoute } from 'const'
import config from 'const/config'

const mailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

function BecomeSpeaker() {
  const theme = useTheme()
  const breakpoint = useBreakpoint()
  const { formatMessage } = useIntl()
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
  } = useForm()
  const { locale } = useIntl()
  const isPolish = locale === 'pl'

  const { data: dictionary } = useSelector(({ app }) => app.dictionary)

  const [formSubmit, formSubmitting] = useFormspark({
    formId: config.BECOME_SPEAKER_FORMSPARK_ID,
  })
  const [formSuccess, setFormSuccess] = useState(false)

  const watchTitle = watch('title', '')
  const watchDescription = watch('description', '')
  const { localizeRouteKey } = useLocalizeRoute()

  const onSubmit = async (data) => {
    // if (!!!data.cities.length) {
    //   setError('cities', {
    //     type: 'manual',
    //     message: formatMessage({ id: 'formCitiesError' }),
    //   })
    //   return
    // }

    await formSubmit(data).then(() => setFormSuccess(true))
  }

  const tracks = useMemo(
    () =>
      dictionary?.tracks?.map((track) => ({
        label: isPolish ? track.name : track?.name_en ?? track.name,
        value: isPolish ? track.name : track?.name_en ?? track.name,
      })),
    [dictionary, locale]
  )

  const comments = useMemo(
    () => [
      {
        comment: formatMessage({ id: 'becomeSpeakerCommentFirst' }),
        author: formatMessage({ id: 'becomeSpeakerCommentFirstAuthor' }),
        information: formatMessage(
          { id: 'becomeSpeakerCommentFirstInformation' },
          {
            strong: (chunk) => <strong>{chunk}</strong>,
          }
        ),
      },
      {
        comment: formatMessage({ id: 'becomeSpeakerCommentSecond' }),
        author: formatMessage({ id: 'becomeSpeakerCommentSecondAuthor' }),
        information: formatMessage(
          { id: 'becomeSpeakerCommentSecondInformation' },
          {
            strong: (chunk) => <strong>{chunk}</strong>,
          }
        ),
      },
    ],
    []
  )

  return (
    <Wrapper>
      <SBecomeSpeaker>
        <SectionHeader
          id="speakersForm"
          heading="becomeSpeakerHeading"
          headingUnderlineColor={theme.colors.green}
        />
        <SContainer>
          <SAmenities>
            <h3>{formatMessage({ id: 'becomeSpeakerAmenitiesHeading' })}</h3>
            <SAmenitiesList>
              <div>
                <TransportIcon />
                <p>{formatMessage({ id: 'becomeSpeakerAmenityTransport' })}</p>
              </div>
              <div>
                <AccommodationIcon />
                <p>
                  {formatMessage({ id: 'becomeSpeakerAmenityAccommodation' })}
                </p>
              </div>
              <div>
                <SupperIcon />
                <p>{formatMessage({ id: 'becomeSpeakerAmenitySupper' })}</p>
              </div>
              <div>
                <PadlockIcon />
                <p>{formatMessage({ id: 'becomeSpeakerAmenityAccess' })}</p>
              </div>
            </SAmenitiesList>
            {!breakpoint.lg ? (
              <SComments>
                {comments.map(({ comment, author, information }, index) => (
                  <SComment key={`${author}-${index}-comment`}>
                    <p className="comment">{comment}</p>
                    <p className="author">{author}</p>
                    <p className="author-information">{information}</p>
                  </SComment>
                ))}
              </SComments>
            ) : null}
          </SAmenities>
          <SFormContainer>
            {formSuccess ? (
              <BecomeSpeakerSuccess />
            ) : (
              <>
                <h3 className="center">
                  {formatMessage(
                    { id: 'becomeSpeakerFormHeading' },
                    {
                      span: (chunk) => <span>{chunk}</span>,
                    }
                  )}
                </h3>
                <form
                  id="become-speaker-form"
                  name="become-speaker-form"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <SFormGroup error={errors.firstName}>
                    <label htmlFor="first-name">
                      {formatMessage({ id: 'formFirstName' })}
                    </label>
                    <input
                      id="first-name"
                      type="text"
                      {...register('firstName', {
                        required: formatMessage({ id: 'formFirstNameError' }),
                      })}
                    />
                    {errors.firstName ? (
                      <p className="error">{errors.firstName?.message}</p>
                    ) : null}
                  </SFormGroup>
                  <SFormGroup error={errors.surname}>
                    <label htmlFor="surname">
                      {formatMessage({ id: 'formSurname' })}
                    </label>
                    <input
                      id="surname"
                      name="surname"
                      type="text"
                      {...register('surname', {
                        required: formatMessage({ id: 'formSurnameError' }),
                      })}
                    />
                    {errors.surname ? (
                      <p className="error">{errors.surname?.message}</p>
                    ) : null}
                  </SFormGroup>
                  <SFormGroup error={errors.email}>
                    <label htmlFor="email">
                      {formatMessage({ id: 'formEmail' })}
                    </label>
                    <input
                      id="email"
                      type="mail"
                      {...register('email', {
                        required: formatMessage({ id: 'formEmailError' }),
                        pattern: {
                          value: mailRegex,
                          message: formatMessage({ id: 'formEmailError' }),
                        },
                      })}
                    />
                    {errors.email ? (
                      <p className="error">{errors.email?.message}</p>
                    ) : null}
                  </SFormGroup>
                  <SFormGroup error={errors.companyName}>
                    <label htmlFor="company-name">
                      {formatMessage({ id: 'formCompanyName' })}
                    </label>
                    <input
                      id="company-name"
                      type="text"
                      {...register('companyName', {
                        required: formatMessage({ id: 'formCompanyNameError' }),
                      })}
                    />
                    {errors.companyName ? (
                      <p className="error">{errors.companyName?.message}</p>
                    ) : null}
                  </SFormGroup>
                  <SFormGroup error={errors.technologies}>
                    <label htmlFor="technologies">
                      {formatMessage({ id: 'formTechnologies' })}
                    </label>
                    <Controller
                      name="technologies[]"
                      defaultValue={false}
                      control={control}
                      rules={{
                        required: formatMessage({
                          id: 'formTechnologiesError',
                        }),
                        min: {
                          value: 1,
                          message: formatMessage({
                            id: 'formTechnologiesError',
                          }),
                        },
                      }}
                      render={({ field: { onChange } }) => (
                        <Select
                          id="technologies"
                          name="technologies"
                          options={technologies}
                          onChange={onChange}
                          error={errors.technologies}
                        />
                      )}
                    />
                    {errors.lectureType ? (
                      <p className="error">{errors.lectureType?.message}</p>
                    ) : null}
                  </SFormGroup>
                  <SFormGroup error={errors.lectureType}>
                    <label htmlFor="lectureType">
                      {formatMessage({ id: 'formLectureType' })}
                    </label>
                    <Controller
                      name="lectureType"
                      defaultValue={false}
                      control={control}
                      rules={{
                        required: formatMessage({
                          id: 'formLectureTypeError',
                        }),
                        min: {
                          value: 1,
                          message: formatMessage({
                            id: 'formLectureTypeError',
                          }),
                        },
                        max: {
                          value: 1,
                          message: formatMessage({
                            id: 'formLectureTypeError',
                          }),
                        },
                      }}
                      render={({ field: { onChange } }) => (
                        <Select
                          id="lecture-type"
                          name="lectureType"
                          options={[
                            {
                              label: 'Conference session',
                              value: 'conference-session',
                            },
                            {
                              label: 'Workshop',
                              value: 'workshop',
                            },
                          ]}
                          onChange={onChange}
                          error={errors.lectureType}
                          isMulti={false}
                        />
                      )}
                    />
                    {errors.technologyTrack ? (
                      <p className="error">{errors.technologyTrack?.message}</p>
                    ) : null}
                  </SFormGroup>
                  <SFormGroup error={errors.technologyTrack}>
                    <label htmlFor="technologyTrack">
                      {formatMessage({ id: 'formTechnologyTrack' })}
                    </label>
                    <Controller
                      name="technologyTrack"
                      defaultValue={false}
                      control={control}
                      rules={{
                        required: formatMessage({
                          id: 'formTechnologyTrackError',
                        }),
                        min: {
                          value: 1,
                          message: formatMessage({
                            id: 'formTechnologyTrackError',
                          }),
                        },
                      }}
                      render={({ field: { onChange } }) => (
                        <Select
                          id="technology-track"
                          name="technologyTrack"
                          options={tracks}
                          onChange={onChange}
                          error={errors.technologyTrack}
                          isMulti={false}
                        />
                      )}
                    />
                    {errors.technologyTrack ? (
                      <p className="error">{errors.technologyTrack?.message}</p>
                    ) : null}
                  </SFormGroup>
                  {/* <SFormGroup error={errors.cities}>
                    <label>{formatMessage({ id: 'formCities' })}</label>
                    <div className="form-row">
                      <div>
                        <input
                          type="checkbox"
                          id="tri-city"
                          value="tricity"
                          {...register('cities[]', {
                            required: formatMessage({ id: 'formCitiesError' }),
                            min: {
                              value: 1,
                              message: formatMessage({ id: 'formCitiesError' }),
                            },
                          })}
                        />
                        <label htmlFor="tri-city">
                          {formatMessage({ id: '3city' })},{' '}
                          {formatDate(new Date('2021/09/21'), {
                            month: 'long',
                            day: 'numeric',
                          })}
                        </label>
                      </div>
                      <div>
                        <input
                          type="checkbox"
                          id="warsaw"
                          value="warsaw"
                          {...register('cities[]', {
                            required: formatMessage({ id: 'formCitiesError' }),
                            min: {
                              value: 1,
                              message: formatMessage({ id: 'formCitiesError' }),
                            },
                          })}
                        />
                        <label htmlFor="warsaw">
                          {formatMessage({ id: 'warsaw' })},{' '}
                          {formatDate(new Date('2021/09/23'), {
                            month: 'long',
                            day: 'numeric',
                          })}
                        </label>
                      </div>
                    </div>
                    {errors.cities ? (
                      <p className="error">{errors.cities?.message}</p>
                    ) : null}
                  </SFormGroup> */}
                  <SFormGroup error={errors.title}>
                    <label htmlFor="title">
                      {formatMessage({ id: 'formTitle' })}{' '}
                      <span>({watchTitle.length}/100)</span>
                    </label>
                    <input
                      type="text"
                      id="title"
                      {...register('title', {
                        required: formatMessage({ id: 'formTitleError' }),
                        maxLength: {
                          value: 100,
                          message: formatMessage({ id: 'formTitleError' }),
                        },
                      })}
                      maxLength={100}
                    />
                    {errors.title ? (
                      <p className="error">{errors.title?.message}</p>
                    ) : null}
                  </SFormGroup>
                  <SFormGroup error={errors.description}>
                    <label htmlFor="description">
                      {formatMessage({ id: 'formDescription' })}
                      <span>({watchDescription.length}/850)</span>
                    </label>
                    <div className="textarea">
                      <textarea
                        id="description"
                        name="description"
                        rows={8}
                        {...register('description', {
                          required: formatMessage({
                            id: 'formDescriptionError',
                          }),
                          maxLength: {
                            value: 850,
                            message: formatMessage({
                              id: 'formDescriptionError',
                            }),
                          },
                        })}
                        maxLength={850}
                      />
                    </div>
                    {errors.description ? (
                      <p className="error">{errors.description?.message}</p>
                    ) : null}
                  </SFormGroup>
                  <SSend
                    disabled={Object.keys(errors).length || formSubmitting}
                    type="submit"
                  >
                    {formatMessage({ id: 'formSend' })}
                  </SSend>
                </form>
              </>
            )}
          </SFormContainer>
        </SContainer>
        <SFormPolicy>
          {formatMessage(
            { id: 'becomeSpeakerFormPolicy' },
            {
              a: ([chunk]) => {
                return (
                  <a
                    href={localizeRouteKey(AppRoute.PrivacyPolicy)}
                    target="_blank"
                  >
                    {chunk}
                  </a>
                )
              },
            }
          )}
        </SFormPolicy>
      </SBecomeSpeaker>
    </Wrapper>
  )
}

export default BecomeSpeaker
