import React, { Component, useEffect, useState } from 'react'
import {
  SFormContainer,
  SHeader,
  SHeaderDescription,
  SFormGroup,
  SInlineLabel,
  SLabelDisclaimer,
  SFormSection,
  SSend,
} from './SpeakerRegistrationForm.styled'
import { useIntl } from 'react-intl'
import { useForm, Controller } from 'react-hook-form'
import technologies from '../BecomeSpeaker/technologies'
import {
  Select,
  LevelSelect,
  DropzoneInput,
  CreatableMultiselect,
  Loader,
  FormSendSuccess,
} from '../../components'

import client from 'api/client'
import config from 'const/config'
import { AppRoute } from 'const'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { useSelector } from 'react-redux'
import { defaultTimezone } from 'const/defaultTimezone'

const mailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

function SpeakerRegistrationForm({ tagsList, tshirtSizes }) {
  const { formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm()

  register('skills', null)
  const [files, setFiles] = useState()
  const [fileUploading, setFileUploading] = useState()
  const [dataSend, setDataSend] = useState(false)
  const [message, setMessage] = useState('')
  const [formSuccess, setFormSuccess] = useState(false)
  const [skillsPoint, setSkillsPoint] = useState(6)
  const [checkSvg, setCheckSvg] = useState(false)

  const { data: dictionary } = useSelector(({ app }) => app.dictionary)

  const speakerDescription = watch('speaker_description', '')

  const handleSkillsLevel = (v) => {
    setValue(
      'skills',
      v.map((skill) => ({ [skill.name]: skill.value }))
    )
    const sumPoints = v.map((skill) => skill.value).reduce((a, b) => a + b)
    setSkillsPoint(sumPoints)
  }

  const handleImageDelete = (element) => {
    setFiles({ ...files, [element]: '' })
  }

  const onSubmit = (data) => {
    setDataSend(true)
    client({
      method: 'POST',
      endpoint: 'api/speaker/post',
      body: JSON.stringify({
        ...data,
        speaker_raw_image_path: files.image,
        speaker_company_raw_image_path: files.companyImage,
      }),
    })
      .then((res) => {
        setDataSend(false)
        setMessage(res.message)
        setFormSuccess(true)
        reset()
      })
      .catch((e) => {
        setMessage(e.message)
        setDataSend(false)
        console.error('Request error', e)
      })
  }
  const renderLoader = () => (
    <div>
      <Loader size={6} width={30} height={30} />
    </div>
  )

  const renderResponse = () => (
    <div className="content">
      <p className="heading">{message}</p>
    </div>
  )
  const handleDropType = (uploadedFile, imageType, svgUpload) => {
    setFileUploading(true)
    const formData = new FormData()

    uploadedFile.forEach((file) => {
      formData.append('file', file)
    })

    fetch(`${config.CE_API_URL}/file-upload`, {
      method: 'POST',
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          return response.text()
        }

        return ''
      })
      .then((fileName) => {
        setFiles({
          ...files,
          [imageType]: fileName,
        })
        setFileUploading(false)
      })
      .catch((e) => {
        console.error(e)
        setFileUploading(false)
      })
  }

  useEffect(() => {}, [files])

  const onDropImage = (files) => {
    handleDropType(files, 'image')
  }

  const onDropCompanyImage = (files) => {
    const [file] = files
    if (file.type === 'image/svg+xml') {
      handleDropType(files, 'companyImage', true)
      setCheckSvg(false)
    } else {
      setCheckSvg(true)
    }
  }

  return (
    <>
      {formSuccess ? (
        <FormSendSuccess />
      ) : (
        <SFormContainer>
          <SHeader>Dear Speaker,</SHeader>
          <SHeaderDescription>
            Please fill out the form below. This information will be published
            on Code Europe website and in promotional materials.
          </SHeaderDescription>
          <form
            id="speaker-registration-form"
            name="speaker-registration-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <SFormSection>
              <SFormGroup error={errors?.speaker_name}>
                <label htmlFor="name">Name</label>
                <input
                  id="name"
                  type="text"
                  {...register('speaker_name', {
                    required: 'Speaker name is required',
                  })}
                />
                {errors.speaker_name ? (
                  <p className="error">{errors.speaker_name?.message}</p>
                ) : null}
              </SFormGroup>
              <SFormGroup error={errors?.speaker_email}>
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  {...register('speaker_email', {
                    required: 'Email is required',
                    pattern: {
                      value: mailRegex,
                      message: 'Fix email address format',
                    },
                  })}
                />
                {errors.speaker_email ? (
                  <p className="error">{errors.speaker_email?.message}</p>
                ) : null}
              </SFormGroup>
              <SFormGroup error={errors?.speaker_company}>
                <label htmlFor="company-name">Company name</label>
                <input
                  id="company-name"
                  type="text"
                  {...register('speaker_company', {
                    required: 'Company name is required',
                  })}
                />
                {errors.speaker_company ? (
                  <p className="error">{errors.speaker_company?.message}</p>
                ) : null}
              </SFormGroup>
              <SFormGroup error={errors?.speaker_position}>
                <label htmlFor="title">Title</label>
                <input
                  id="title"
                  type="text"
                  {...register('speaker_position', {
                    required: 'Position is required',
                  })}
                />
                {errors.speaker_position ? (
                  <p className="error">{errors.speaker_position?.message}</p>
                ) : null}
              </SFormGroup>
              <SFormGroup error={errors?.speaker_description}>
                <label htmlFor="bio">
                  Bio
                  <span>({speakerDescription.length}/850)</span>
                </label>
                <div className="textarea">
                  <textarea
                    id="bio"
                    rows={8}
                    {...register('speaker_description', {
                      required: 'Subject area is required',
                      maxLength: {
                        value: 850,
                        message: 'The description cannot exceed 850 characters',
                      },
                      minLength: {
                        value: 10,
                        message:
                          'The description must contain at least 10 characters',
                      },
                    })}
                  />
                </div>
                {errors.speaker_description ? (
                  <p className="error">{errors.speaker_description?.message}</p>
                ) : null}
              </SFormGroup>
            </SFormSection>

            <SFormSection>
              <SFormGroup>
                <label>Meet me in:</label>
                {dictionary.cities
                  .filter((c) => c.is_current)
                  .map((city) => (
                    <div className="form-row">
                      <div>
                        <input
                          type="checkbox"
                          id={city.slug}
                          name="speaker_city_id_list"
                          value={city.id}
                          {...register('speaker_city_id_list', {
                            required: 'Select city',
                            min: {
                              value: 1,
                              message: 'Select city',
                            },
                          })}
                        />
                        <label htmlFor={city.slug}>
                          {city.second_date ? (
                            <>
                              {new Date(city.date).toLocaleDateString('en-US', {
                                dateStyle: 'long',
                                timeZone: defaultTimezone,
                              })}{' '}
                              <strong>+</strong>{' '}
                              {new Date(city.second_date).toLocaleDateString(
                                'en-US',
                                {
                                  dateStyle: 'long',
                                  timeZone: defaultTimezone,
                                }
                              )}{' '}
                              | {city.name_en}
                            </>
                          ) : (
                            <>
                              {new Date(city.date).toLocaleDateString('en-US', {
                                dateStyle: 'long',
                                timeZone: defaultTimezone,
                              })}{' '}
                              | {city.name_en}
                            </>
                          )}
                        </label>
                      </div>
                    </div>
                  ))}
                {/* <div className="form-row">
                  <div>
                    <input
                      type="checkbox"
                      id="cracow"
                      name="speaker_city_id_list[]"
                      value="7"
                      {...register('speaker_city_id_list[]', {
                        required: 'Select city',
                        min: {
                          value: 1,
                          message: 'Select city',
                        },
                      })}
                    />
                    <label htmlFor="cracow">
                      May 22nd to 23rd, 2023 | Cracow
                    </label>
                  </div>
                </div>
                <div className="form-row">
                  <div>
                    <input
                      type="checkbox"
                      id="warsaw"
                      name="speaker_city_id_list[]"
                      value="5"
                      {...register('speaker_city_id_list[]', {
                        required: 'Select city',
                        min: {
                          value: 1,
                          message: 'Select city',
                        },
                      })}
                    />
                    <label htmlFor="warsaw">
                      May 25th to 26th, 2023 | Warsaw
                    </label>
                  </div>
                </div> */}
                {errors.speaker_city_id_list ? (
                  <p className="error">
                    {errors.speaker_city_id_list?.message}
                  </p>
                ) : null}
              </SFormGroup>
            </SFormSection>

            <SFormSection>
              <SFormGroup>
                <label htmlFor="technologies">Technologies</label>
                <SLabelDisclaimer>
                  If any technology or scientific field is missing, you may add
                  yours by typing what you want.
                </SLabelDisclaimer>
                <Controller
                  control={control}
                  name="speaker_tags_id_list[]"
                  defaultValue={false}
                  rules={{
                    required: 'Select technologies from list',
                    min: {
                      value: 1,
                      message: 'Select technologies from list',
                    },
                  }}
                  render={({ field: { onChange } }) => (
                    <CreatableMultiselect
                      id="technologies"
                      name="speaker_tags_id_list[]"
                      options={tagsList?.map((value) => {
                        return {
                          label: value.tag_name,
                          value: value.tag_id,
                        }
                      })}
                      onChange={onChange}
                      formatCreateLabel={(inputValue) => {
                        return 'Add your proposal: ' + inputValue
                      }}
                    />
                  )}
                />
                {errors.technologies ? (
                  <p className="error">{errors.technologies?.message}</p>
                ) : null}
              </SFormGroup>
            </SFormSection>

            <SFormSection>
              <SFormGroup>
                <label>
                  My skills:
                  <span>({skillsPoint}/22)</span>
                </label>
                <SLabelDisclaimer>
                  Please provide us with the information about competences (from
                  1 - basic, to 5 - expert)
                </SLabelDisclaimer>
                <LevelSelect
                  config={{
                    speakers_think: 'Critical Thinking',
                    speakers_problems: 'Problems Solving',
                    speakers_program: 'Programming',
                    speakers_system: 'System Analysis',
                    speakers_error: 'Bug fixing',
                    speakers_lazi: 'Laziness',
                  }}
                  defaultValue={1}
                  levelPoints={5}
                  totalPointsLimit={22}
                  onChange={handleSkillsLevel}
                />
              </SFormGroup>
            </SFormSection>

            <SFormSection>
              <label>Find me on LINK</label>

              <SFormGroup>
                <label htmlFor="link_twitter">Twitter</label>
                <input
                  {...register('speaker_twitter_url')}
                  id="link_twitter"
                  name="speaker_twitter_url"
                  type="text"
                />
              </SFormGroup>
              <SFormGroup>
                <label htmlFor="link_fb">Facebook</label>
                <input
                  {...register('speaker_facebook_url')}
                  id="link_fb"
                  name="speaker_facebook_url"
                  type="text"
                />
              </SFormGroup>
              <SFormGroup>
                <label htmlFor="link_gh">Github</label>
                <input
                  {...register('speaker_github_url')}
                  id="link_gh"
                  name="speaker_github_url"
                  type="text"
                />
              </SFormGroup>
              <SFormGroup>
                <label htmlFor="link_linkedin">Linkedin</label>
                <input
                  {...register('speaker_linkedin_url')}
                  id="link_linkedin"
                  name="speaker_linkedin_url"
                  type="text"
                />
              </SFormGroup>
              <SFormGroup>
                <label htmlFor="link_other">Other</label>
                <input
                  {...register('speaker_other_url')}
                  id="link_other"
                  name="speaker_other_url"
                  type="text"
                />
              </SFormGroup>
            </SFormSection>
            {/* <SFormSection>
              <SFormGroup>
                <label htmlFor="tshirt-size">T-shirt size</label>

                <Controller
                  name="speaker_tshirt_size"
                  defaultValue={false}
                  control={control}
                  rules={{
                    required: 'T-shirt size is required',
                    min: {
                      value: 1,
                      message: 'Choose your t-shirt size',
                    },
                  }}
                  render={({ field: { onChange } }) => (
                    <Select
                      id="tshirt-size"
                      name="speaker_tshirt_size"
                      options={tshirtSizes}
                      onChange={onChange}
                      isMulti={false}
                    />
                  )}
                />

                {errors.speaker_tshirt_size ? (
                  <p className="error">{errors.speaker_tshirt_size?.message}</p>
                ) : null}
              </SFormGroup>
            </SFormSection> */}
            <SFormSection>
              <SFormGroup>
                <label>Video recording:</label>
                <SLabelDisclaimer>
                  We record video every lecture in order to provide it to our
                  Tech community, which in turn is appreciated greatly. Please
                  confirm if we can publish a recording of your's Code Europe
                  appearance.
                  <a
                    href={localizeRouteKey(AppRoute.RecordingAgreement)}
                    target="_blank"
                  >
                    Legal note
                  </a>
                </SLabelDisclaimer>
                <div className="form-row">
                  <div>
                    <input
                      type="radio"
                      id="video_recording-yes"
                      value="1"
                      {...register('speaker_video_recording_permission', {
                        required: 'Select one option',
                      })}
                    />
                    <label htmlFor="video_recording-yes">Confirming</label>
                  </div>
                </div>
                <div className="form-row">
                  <div>
                    <input
                      type="radio"
                      id="video_recording-no"
                      value="0"
                      {...register('speaker_video_recording_permission', {
                        required: 'Select one option',
                      })}
                    />
                    <label htmlFor="video_recording-no">Not confirming</label>
                  </div>
                </div>
                {errors.videoRecording ? (
                  <p className="error">{errors.videoRecording?.message}</p>
                ) : null}
              </SFormGroup>
            </SFormSection>
            <SFormSection>
              <SFormGroup>
                <label>Image</label>
                <SLabelDisclaimer>
                  Upload your photo (it will be visible on your profile at
                  CodeEurope.pl. Required measurements: 400x400 px, file format
                  .jpg or .png).
                </SLabelDisclaimer>
                <DropzoneInput
                  uploadedFile={files?.image}
                  uploadingProgress={fileUploading}
                  onDrop={onDropImage}
                  multiple={false}
                  onRemove={() => handleImageDelete('image')}
                />
              </SFormGroup>
            </SFormSection>
            {/*<SFormSection>*/}
            {/*  <SFormGroup>*/}
            {/*    <label>Company Logo</label>*/}
            {/*    <SLabelDisclaimer>*/}
            {/*      Upload Company Logo (it will be visible on your profile at*/}
            {/*      CodeEurope.pl. Required measurements: around 200x144 px, file*/}
            {/*      format .svg).{' '}*/}
            {/*    </SLabelDisclaimer>*/}
            {/*    <DropzoneInput*/}
            {/*      uploadedFile={files?.companyImage}*/}
            {/*      uploadingProgress={fileUploading}*/}
            {/*      onDrop={onDropCompanyImage}*/}
            {/*      multiple={false}*/}
            {/*      onRemove={() => handleImageDelete('companyImage')}*/}
            {/*    />*/}
            {/*    {checkSvg ? (*/}
            {/*      <p className="error">Please upload svg file</p>*/}
            {/*    ) : null}*/}
            {/*  </SFormGroup>*/}
            {/*</SFormSection>*/}
            <SLabelDisclaimer>
              Please check the
              <a
                href={localizeRouteKey(AppRoute.PersonalDataProcessing)}
                target="_blank"
              >
                legal note
              </a>
              before clicking 'Send"
            </SLabelDisclaimer>
            <SSend type="submit" disabled={dataSend}>
              {dataSend ? renderLoader() : 'Send'}
            </SSend>
          </form>
          <SHeaderDescription>{renderResponse()}</SHeaderDescription>
        </SFormContainer>
      )}
    </>
  )
}

export default SpeakerRegistrationForm
