import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useForm, Controller } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import client from 'api/client'

import {
  TicketProcessBox,
  TicketUserSteper,
  TicketProcessInput,
  TicketProcessCheckbox,
  Select,
} from '../'
import Button from 'components/Button/Button'

import { TicketStep } from 'state/reducers/tickets/ticketsReducer'
import { changeStep, setFields } from 'state/reducers/tickets/ticketsActions'

function TicketUserBadge({ fields, employmentPosition, tracks }) {
  const dispatch = useDispatch()
  const { formatMessage } = useIntl()

  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, control, formState, setError, watch } =
    useForm({
      defaultValues: {
        name: fields.name,
        surname: fields.surname,
        job_position: employmentPosition.find(
          (o) => o.value === fields.job_position
        ),
        company: fields.company,
        interesting_path: tracks.find(
          (o) => o.value === fields.interesting_path
        ),
      },
      shouldUnregister: false,
    })

  const jobPosition = watch('job_position')
  const companyRequired =
    jobPosition && jobPosition.id !== 10 && jobPosition.id !== 11

  const handlePrev = () => {
    dispatch(changeStep(TicketStep.REGISTRATION))
  }

  const onSubmit = async (values) => {
    setLoading(true)
    try {
      const body = {
        step: 2,
        ...values,
        job_position: values.job_position.value,
        interesting_path: values.interesting_path.value,
      }

      const response = await client({
        endpoint: 'api/buy/validate',
        method: 'POST',
        body: JSON.stringify(body),
      })

      if (!response.ok) {
        const data = await response.json()
        Object.keys(data).forEach((key) => {
          const error = data[key].flat()
          if (!Array.isArray(error)) return

          const [errorMessage] = error
          setError(key, {
            type: 'required',
            message: formatMessage({ id: errorMessage }),
          })
        })

        throw new Error()
      }

      const fields = { ...body }
      delete fields.name
      delete fields.surname

      window.scrollTo({ top: 0 })
      dispatch(
        setFields({
          type: 'base',
          fields,
        })
      )
      dispatch(changeStep(TicketStep.DATA))
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('[POST] validate user badge step error: ', error)
    }
  }

  return (
    <TicketProcessBox>
      <header>
        <p className="heading">
          {formatMessage({ id: 'tickets.userBadgeHeading' })}
        </p>
        <p className="informations">
          {formatMessage({ id: 'tickets.userBadgeDescription' })}
        </p>
      </header>
      <form
        id="user-badge-form"
        name="user-badge-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TicketProcessInput
          {...{
            label: 'labels.name',
            htmlFor: 'name',
            error: formState.errors.name,
          }}
        >
          <input
            id="name"
            type="text"
            disabled
            {...register('name', {
              required: {
                value: true,
                message: 'errors.nameError',
              },
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.surname',
            htmlFor: 'surname',
            error: formState.errors.surname,
          }}
        >
          <input
            id="surname"
            type="text"
            disabled
            {...register('surname', {
              required: 'errors.surnameError',
            })}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.jobPosition',
            htmlFor: 'job_position',
            error: formState.errors.job_position,
          }}
        >
          <Controller
            name="job_position"
            defaultValue={false}
            control={control}
            rules={{
              required: 'errors.jobPositionError',
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti={false}
                short
                value={value}
                id="job_position"
                name="job_position"
                options={employmentPosition}
                onChange={onChange}
                error={formState.errors.job_position}
              />
            )}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.company',
            htmlFor: 'company',
            error: formState.errors.company,
          }}
        >
          <input
            id="company"
            type="text"
            {...register('company', {
              required: {
                value: companyRequired,
                message: 'errors.companyError',
              },
            })}
          />
        </TicketProcessInput>

        <TicketProcessInput
          {...{
            label: 'labels.interestingPath',
            htmlFor: 'interesting_path',
            error: formState.errors.interesting_path,
          }}
        >
          <Controller
            name="interesting_path"
            defaultValue={false}
            control={control}
            rules={{
              required: 'errors.interestingPathError',
            }}
            render={({ field: { onChange, value } }) => (
              <Select
                isMulti={false}
                short
                value={value}
                id="interesting_path"
                name="interesting_path"
                options={tracks}
                onChange={onChange}
                error={formState.errors.interesting_path}
              />
            )}
          />
        </TicketProcessInput>

        <div className="actions">
          <Button loading={loading}>
            {formatMessage({ id: 'general.next' })}
          </Button>
          <Button type="button" onClick={handlePrev} bordered>
            {formatMessage({ id: 'general.back' })}
          </Button>
        </div>
      </form>
    </TicketProcessBox>
  )
}

export default TicketUserBadge
