import React, { Component, useEffect, useState } from 'react'
import { useForm, Controller, useFieldArray } from 'react-hook-form'

import {
  SFormContainer,
  SHeader,
  SHeaderDescription,
  SFormGroup,
  SLabelDisclaimer,
  SSend,
} from './SpeechDetailsForm.styled'
import {
  Select,
  LevelSelect,
  CreatableMultiselect,
  Loader,
  FormSendSuccess,
} from '../../components'
import technologies from '../BecomeSpeaker/technologies'
import client from '../../api/client'
import { useSelector } from 'react-redux'
import { defaultTimezone } from 'const/defaultTimezone'

const mailRegex =
  /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i

function SpeechDetailsForm() {
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      speaker: [{ name: '', email: '' }],
    },
  })
  const [dataSend, setDataSend] = useState(false)
  const [formSendSuccess, setFormSendSuccess] = useState(false)
  const [message, setMessage] = useState('')
  const { fields, append, prepend, remove } = useFieldArray({
    control,
    name: 'speaker',
  })
  register('experience', null)

  const watchTitle = watch('title', '')
  const shortDescription = watch('description', '')
  const requirements = watch('requirements', '')

  const { data: dictionary } = useSelector(({ app }) => app.dictionary)

  const handleExperienceLevel = ([level]) => {
    setValue('experience', level.value)
  }
  const onSubmit = (data) => {
    setDataSend(true)
    client({
      method: 'POST',
      endpoint: 'api/lecture/post',
      body: JSON.stringify(data),
    })
      .then((res) => {
        setDataSend(false)
        setMessage(res.message)
        setFormSendSuccess(true)
      })
      .catch((e) => {
        setMessage(e.message)
        setDataSend(false)
        console.error('Request error', e)
      })
  }
  const renderLoader = () => (
    <div>
      <Loader size={6} width={30} height={30} />
    </div>
  )
  const renderResponse = () => (
    <div className="content">
      <p className="heading">{message}</p>
    </div>
  )
  return (
    <>
      {formSendSuccess ? (
        <FormSendSuccess />
      ) : (
        <SFormContainer>
          <SHeader>Dear Speaker,</SHeader>
          <SHeaderDescription>
            Please fill out the form below. This information will be published
            on Code Europe website and in promotional materials.
          </SHeaderDescription>
          <form
            id="speech-details-form"
            name="speech-details-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <SFormGroup error={errors?.type}>
              <label>Type of lecture</label>
              <div className="form-row">
                <div>
                  <input
                    type="radio"
                    className="checkbox-as-radio"
                    id="lecture"
                    value="lecture"
                    {...register('type', {
                      required: 'Select speech type',
                    })}
                  />
                  <label htmlFor="lecture">Conference session</label>
                </div>
              </div>
              <div className="form-row">
                <div>
                  <input
                    type="radio"
                    className="checkbox-as-radio"
                    id="workshop"
                    value="workshop"
                    {...register('type', {
                      required: 'Select speech type',
                    })}
                  />
                  <label htmlFor="workshop">Work IT (workshop)</label>
                </div>
              </div>
              {errors?.type ? (
                <p className="error">{errors?.type?.message}</p>
              ) : null}
            </SFormGroup>
            <SFormGroup error={errors?.cities}>
              <label>City</label>
              {dictionary.cities
                .filter((c) => c.is_current)
                .map((city) => (
                  <div className="form-row">
                    <div>
                      <input
                        type="checkbox"
                        id={city.slug}
                        value={city.id}
                        {...register('cities', {
                          required: 'Select city',
                          min: {
                            value: 1,
                            message: 'Select city',
                          },
                        })}
                      />
                      <label htmlFor={city.slug}>
                        {city.second_date ? (
                          <>
                            {new Date(city.date).toLocaleDateString('en-US', {
                              dateStyle: 'long',
                              timeZone: defaultTimezone,
                            })}{' '}
                            <strong>+</strong>{' '}
                            {new Date(city.second_date).toLocaleDateString(
                              'en-US',
                              {
                                dateStyle: 'long',
                                timeZone: defaultTimezone,
                              }
                            )}{' '}
                            | {city.name_en}
                          </>
                        ) : (
                          <>
                            {new Date(city.date).toLocaleDateString('en-US', {
                              dateStyle: 'long',
                              timeZone: defaultTimezone,
                            })}{' '}
                            | {city.name_en}
                          </>
                        )}
                      </label>
                    </div>
                  </div>
                ))}
              {/* <div className="form-row">
                <div>
                  <input
                    type="checkbox"
                    id="cracow"
                    value={7}
                    {...register('cities[]', {
                      required: 'Please select at least one location',
                      min: {
                        value: 1,
                        message: 'Please select at least one location',
                      },
                    })}
                  />
                  <label htmlFor="cracow">
                    May 22nd to 23rd, 2023 | Cracow
                  </label>
                </div>
              </div> */}
              {/* <div className="form-row">
                <div>
                  <input
                    type="checkbox"
                    id="warsaw"
                    value={5}
                    {...register('cities[]', {
                      required: 'Please select at least one location',
                      min: {
                        value: 1,
                        message: 'Please select at least one location',
                      },
                    })}
                  />
                  <label htmlFor="warsaw">
                    May 25th to 26th, 2023 | Warsaw
                  </label>
                </div>
              </div> */}
              {errors?.cities ? (
                <p className="error">{errors?.cities?.message}</p>
              ) : null}
            </SFormGroup>

            {fields.map((item, index) => {
              return (
                <div key={index}>
                  <SFormGroup
                    error={
                      Array.isArray(errors?.speaker) && errors?.speaker[index]
                    }
                  >
                    <label htmlFor="name">Speaker Name</label>
                    <input
                      id="name"
                      type="text"
                      defaultValue={item.name}
                      {...register(`speaker.${index}.name`, {
                        min: {
                          value: 1,
                          message: 'Min one speaker',
                        },
                        required: 'Name field is required',
                      })}
                    />
                    {Array.isArray(errors?.speaker) &&
                    errors?.speaker[index] ? (
                      <p className="error">
                        {errors?.speaker[index]?.name?.message}
                      </p>
                    ) : null}
                  </SFormGroup>
                  <SFormGroup
                    error={
                      Array.isArray(errors?.speaker) && errors?.speaker[index]
                    }
                  >
                    <label htmlFor="email">Speaker E-mail</label>
                    <input
                      id="email"
                      type="email"
                      defaultValue={item.email}
                      {...register(`speaker.${index}.email`, {
                        min: {
                          value: 1,
                          message: 'Min one speaker mail',
                        },
                        pattern: {
                          value: mailRegex,
                          message: 'Enter the valid mail',
                        },
                        required: 'Email field is required',
                      })}
                    />
                    {Array.isArray(errors?.speaker) &&
                    errors?.speaker[index] ? (
                      <p className="error">
                        {errors?.speaker[index]?.email?.message}
                      </p>
                    ) : null}
                  </SFormGroup>
                  {index ? (
                    <button
                      type="button"
                      className="remove-button"
                      onClick={() => remove(index)}
                    >
                      Remove
                    </button>
                  ) : null}
                </div>
              )
            })}

            <button
              type="button"
              className="add-button"
              onClick={() => append({ name: '', email: '' })}
            >
              Add another Speaker
            </button>

            <SFormGroup error={errors?.title}>
              <label htmlFor="title">
                Title of Lecture / Workshop
                <span>({watchTitle.length}/100)</span>
              </label>
              <input
                id="title"
                type="title"
                {...register('title', {
                  required: 'Title field is required',
                  maxLength: {
                    value: 100,
                    message: 'Title field is required',
                  },
                })}
              />
              {errors?.title ? (
                <p className="error">{errors?.title?.message}</p>
              ) : null}
            </SFormGroup>

            <SFormGroup error={errors?.description}>
              <label htmlFor="shortDescription">
                Short description
                <span>({shortDescription.length}/850)</span>
              </label>
              <div className="textarea">
                <textarea
                  id="shortDescription"
                  rows={8}
                  {...register('description', {
                    required: 'Title field is required',
                    maxLength: {
                      value: 850,
                      message: 'Too many characters entered',
                    },
                  })}
                />
              </div>
              {errors?.description ? (
                <p className="error">{errors?.description?.message}</p>
              ) : null}
            </SFormGroup>

            <SFormGroup error={errors?.language}>
              <label htmlFor="language">Lecture language</label>
              <Controller
                name="language"
                defaultValue={false}
                control={control}
                rules={{
                  required: 'Language field is required',
                  max: {
                    value: 1,
                    message: 'Language field is required',
                  },
                }}
                render={({ field: { onChange } }) => (
                  <Select
                    id="language"
                    options={[
                      { label: 'Polski', value: 'pl' },
                      { label: 'English', value: 'en' },
                    ]}
                    onChange={onChange}
                    error={errors?.language}
                    placeholder="Select language"
                    isMulti={false}
                  />
                )}
              />
              {errors?.language ? (
                <p className="error">{errors?.language?.message}</p>
              ) : null}
            </SFormGroup>

            <SFormGroup>
              <label>Required level of experience</label>
              <SLabelDisclaimer>
                Specify the level of experience required from the participants
                (1 - beginners, 3 - advanced)
              </SLabelDisclaimer>
              <LevelSelect
                config={{
                  level: 'Level',
                }}
                defaultValue={1}
                levelPoints={3}
                totalPointsLimit={3}
                onChange={handleExperienceLevel}
              />
            </SFormGroup>

            <SFormGroup error={errors?.track}>
              <label htmlFor="track">Choose track of your Lecture</label>
              <Controller
                name="track"
                defaultValue={false}
                control={control}
                rules={{
                  required: 'Track field is required',
                  max: {
                    value: 1,
                    message: 'Track field is required',
                  },
                }}
                render={({ field: { onChange } }) => (
                  <Select
                    id="track"
                    options={[
                      // { label: 'Cloud Computing', value: 13 },
                      { label: 'Cybersecurity', value: 14 },
                      { label: 'Programming Languages', value: 18 },
                      { label: 'Infrastructure/DevOps', value: 16 },
                      { label: 'Data & AI', value: 15 },
                      {
                        label: 'Development Execution',
                        value: 19,
                      },
                      { label: 'Future & Inspire', value: 17 },
                    ]}
                    onChange={onChange}
                    error={errors?.track}
                    isMulti={false}
                  />
                )}
              />
              {errors?.track ? (
                <p className="error">{errors?.track?.message}</p>
              ) : null}
            </SFormGroup>

            <SFormGroup error={errors?.technologies}>
              <label htmlFor="technologies">Technological fields</label>
              <SLabelDisclaimer>
                If any technology is missing, you may add yours by typing what
                you want.
              </SLabelDisclaimer>
              <Controller
                control={control}
                name="technologies[]"
                defaultValue={false}
                rules={{
                  required: 'Choose at least one technology',
                  min: {
                    value: 1,
                    message: 'Choose at least one technology',
                  },
                }}
                render={({ field: { onChange } }) => (
                  <CreatableMultiselect
                    id="technologies"
                    name="speaker_tags_id_list[]"
                    options={technologies}
                    onChange={onChange}
                    formatCreateLabel={(inputValue) => {
                      return 'Add your proposal: ' + inputValue
                    }}
                  />
                )}
              />
              {errors?.technologies ? (
                <p className="error">{errors?.technologies?.message}</p>
              ) : null}
            </SFormGroup>

            {/* <SFormGroup error={errors?.requirements}>
              <label htmlFor="description">
                Requirements for the participants
                <span>({requirements.length}/850)</span>
              </label>
              <SLabelDisclaimer>
                Fill out if "workshop" was selected
              </SLabelDisclaimer>
              <div className="form-row textarea">
                <textarea
                  id="description"
                  rows={8}
                  {...register('requirements', {
                    maxLength: {
                      value: 850,
                      message: 'Too many characters entered',
                    },
                  })}
                />
              </div>
              {errors?.requirements ? (
                <p className="error">{errors?.requirements?.message}</p>
              ) : null}
            </SFormGroup>
            <SFormGroup error={errors?.participants_count}>
              <label htmlFor="participants-count">
                Number of participants{' '}
              </label>
              <SLabelDisclaimer>
                Fill out if "workshop" was selected{' '}
              </SLabelDisclaimer>
              <div className="form-row">
                <input
                  id="participants-count"
                  type="number"
                  {...register('participants_count', {
                    min: {
                      value: 0,
                      message: 'The number must be greater than 0',
                    },
                    max: {
                      value: 200,
                      message: 'The number must be less than 200',
                    },
                  })}
                />
              </div>
              {errors?.participants_count ? (
                <p className="error">{errors?.participants_count?.message}</p>
              ) : null}
            </SFormGroup> */}

            <SFormGroup>
              <SSend type="submit" disabled={dataSend}>
                {dataSend ? renderLoader() : 'Send'}
              </SSend>
            </SFormGroup>
          </form>
          <SHeaderDescription>{renderResponse()}</SHeaderDescription>
        </SFormContainer>
      )}
    </>
  )
}

export default SpeechDetailsForm
