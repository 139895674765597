import { useEffect, useState, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { AppRoute } from 'const'

import { Button, TicketProcessInput } from 'components'
import TicketProcessBox from 'components/TicketProcessBox/TicketProcessBox.styled'

import useLocalizeRoute from 'utils/useLocalizeRoute'
import { useDispatch, useSelector } from 'react-redux'
import { buyTicket, changeStep } from 'state/reducers/tickets/ticketsActions'
import { TicketStep } from 'state/reducers/tickets/ticketsReducer'
import { mailRegex } from 'components/TicketUserRegistration/TicketUserRegistration'
import client from 'api/client'
import { getUser, setIsAuthenticated } from 'state/reducers/user/userActions'
import config from 'const/config'

function SignIn() {
  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const { locale, formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  const { items, price } = useSelector(({ tickets }) => tickets)
  const { register, handleSubmit, formState, setError } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  })

  const afterForgotPassword = location.state?.forgotPassword
  const afterPasswordReset = location.state?.resetPassword
  const ticketProcess = location.state?.from === AppRoute.BuyTicket

  const handlePrev = () => {
    history.goBack()
  }

  const onSubmit = async (values) => {
    setLoading(true)
    try {
      const response = await client({
        endpoint: 'api/login',
        method: 'POST',
        body: JSON.stringify(values),
      })

      if (!response.ok) {
        await dispatch(setIsAuthenticated(false))
        setLoading(false)
        throw new Error()
      }

      const { token } = await response.json()
      localStorage.setItem(config.STORAGE_TOKEN_KEY, token)

      const user = await dispatch(getUser(locale))
      if (user.payload && ticketProcess) {
        const isFree = price.priceAfterDiscount === '0.00'
        const isGroup = items.selected.amount > 1

        if (isFree) {
          await dispatch(buyTicket({ isFree, locale, isGroup }))
          setLoading(false)
          return
        }

        await dispatch(changeStep(TicketStep.PAYMENT_DATA))
        setLoading(false)
        history.replace(localizeRouteKey(AppRoute.BuyTicket))
        return
      }

      setLoading(false)
      history.push(localizeRouteKey(AppRoute.User))
    } catch (error) {
      setLoading(false)
      setError('email', { type: 'required' })
      console.log('[POST] sign in user error: ', error)
    }
  }

  useEffect(() => {
    if (window?.callpage) {
      window.callpage('api.button.hide')
    }
  }, [])

  return (
    <div>
      <TicketProcessBox margin>
        <div>
          <p className="heading">{formatMessage({ id: 'signin.heading' })}</p>
          <div className="sign-in">
            {formatMessage(
              { id: 'signin.subheading' },
              {
                a: ([chunk]) => (
                  <Link to={localizeRouteKey(AppRoute.BuyTicket)}>{chunk}</Link>
                ),
              }
            )}
          </div>
          {afterForgotPassword && formState.submitCount === 0 ? (
            <p className="reset-password-success">
              {formatMessage({ id: 'user.forgotSuccess' })}.
            </p>
          ) : null}
          {afterPasswordReset && formState.submitCount === 0 ? (
            <p className="reset-password-success">
              {formatMessage({ id: 'user.resetSuccess' })}.
            </p>
          ) : null}
          {formState.errors?.email ? (
            <p className="sign-in-error">
              {formatMessage({ id: 'errors.incorrectLoginData' })}
            </p>
          ) : null}
        </div>

        <form
          id="sign-in-form"
          name="sign-in-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TicketProcessInput
            {...{
              label: 'labels.email',
              htmlFor: 'email',
            }}
          >
            <input
              id="email"
              type="text"
              {...register('email', {
                required: {
                  value: true,
                  message: 'errors.emailError',
                },
                pattern: {
                  value: mailRegex,
                  message: 'errors.emailError',
                },
              })}
            />
          </TicketProcessInput>
          <TicketProcessInput
            {...{
              label: 'labels.password',
              htmlFor: 'password',
            }}
          >
            <input
              id="password"
              type="password"
              {...register('password', {
                required: {
                  value: true,
                  message: 'errors.passwordError',
                },
              })}
            />
          </TicketProcessInput>
          <div className="forgot-password">
            <Link to={localizeRouteKey(AppRoute.ForgotPassword)}>
              {formatMessage({ id: 'signin.forgotPassword' })}?
            </Link>
          </div>
          {ticketProcess ? (
            <div className="actions">
              <Button className="button" disabled={loading} loading={loading}>
                {formatMessage({ id: 'general.next' })}
              </Button>
              <Button
                onClick={handlePrev}
                bordered
                type="button"
                className="button"
              >
                {formatMessage({ id: 'general.back' })}
              </Button>
            </div>
          ) : (
            <div className="actions single">
              <Button disabled={loading} loading={loading} className="button">
                {formatMessage({ id: 'signIn' })}
              </Button>
            </div>
          )}
        </form>
      </TicketProcessBox>
    </div>
  )
}

export { SignIn }
