import React from 'react'
import { useForm } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import { Button, TicketProcessBox, TicketProcessInput } from 'components'
import useLocalizeRoute from 'utils/useLocalizeRoute'
import { AppRoute } from 'const'
import client from 'api/client'

function ResetPassword() {
  const history = useHistory()
  const location = useLocation()

  const params = new URLSearchParams(location.search)

  const email = params.get('email')
  const token = params.get('token')

  const { locale, formatMessage } = useIntl()
  const { localizeRouteKey } = useLocalizeRoute()

  const { register, handleSubmit, formState, setError } = useForm({
    defaultValues: { password: '', password_confirmation: '' },
  })

  const handlePrev = () => {
    history.replace(localizeRouteKey(AppRoute.SignIn))
  }

  const onSubmit = async (values) => {
    try {
      const response = await client({
        endpoint: 'api/reset-password',
        method: 'POST',
        body: JSON.stringify({
          token,
          email,
          ...values,
        }),
      })

      if (!response.ok) {
        const data = await response.json()
        Object.keys(data).forEach((key) => {
          if (key === 'token' || key === 'email') {
            history.replace(localizeRouteKey(AppRoute.ForgotPassword))
            return
          }

          const error = data[key].flat()
          if (!Array.isArray(error)) return

          const [errorMessage] = error
          setError(key, {
            type: 'required',
            message: errorMessage,
          })
        })

        throw new Error()
      }

      history.replace(localizeRouteKey(AppRoute.SignIn), {
        resetPassword: true,
      })
    } catch (error) {
      console.log('[POST] forgot password', error)
    }
  }

  if (!token || !email) {
    return <Redirect to={localizeRouteKey(AppRoute.SignIn)} />
  }

  return (
    <TicketProcessBox margin>
      <h2 className="heading">
        {formatMessage({ id: 'user.resetPasswordHeading' })}
      </h2>
      <p className="informations">
        {formatMessage({ id: 'user.resetPasswordDescription' })}
      </p>

      <form
        id="reset-password-form"
        name="reset-password-form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <TicketProcessInput
          {...{
            label: 'labels.password',
            htmlFor: 'password',
            description: 'labels.passwordRequirements',
            error: formState.errors.password,
          }}
        >
          <input
            id="password"
            type="password"
            {...register('password', {
              required: {
                value: true,
                message: 'errors.passwordError',
              },
            })}
          />
        </TicketProcessInput>
        <TicketProcessInput
          {...{
            label: 'labels.passwordConfirm',
            htmlFor: 'password-confirm',
            description: 'labels.passwordEqual',
            error: formState.errors.password_confirmation,
          }}
        >
          <input
            id="password-confirm"
            type="password"
            {...register('password_confirmation', {
              required: {
                value: true,
                message: 'errors.passwordError',
              },
            })}
          />
        </TicketProcessInput>
        <div className="actions">
          <Button
            disabled={formState.isSubmitting}
            loading={formState.isSubmitting}
          >
            {formatMessage({ id: 'user.resetPasswordSubmit' })}
          </Button>
          <Button type="button" onClick={handlePrev} bordered>
            {formatMessage({ id: 'general.back' })}
          </Button>
        </div>
      </form>
    </TicketProcessBox>
  )
}

export { ResetPassword }
